import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/seo"
import BannerResearch from "../components/organisms/BannerResearch"
import HorizontalCards from "../components/organisms/HorizontalCards"
import VerticalCards from "../components/organisms/VerticalCards"
import Logos from "../components/organisms/Logos"
// import VerticalDetailsCards from "../components/organisms/VerticalDetailsCards"

const Research = () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { type: { eq: "research_page" } } }
        ) {
          edges {
            node {
              frontmatter {
                banner {
                  header
                  body
                }
                horizontalCards1 {
                  header
                  ctaText
                  items {
                    body
                    url
                    image_path {
                      childImageSharp {
                        gatsbyImageData(placeholder: BLURRED)
                      }
                    }
                  }
                }
                logos {
                  header
                  items {
                    image_path {
                      childImageSharp {
                        gatsbyImageData(placeholder: BLURRED)
                      }
                    }
                  }
                }
                verticalCards2 {
                  header
                  ctaText
                  items {
                    body
                    url
                    image_path {
                      childImageSharp {
                        gatsbyImageData(placeholder: BLURRED)
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  return (
    <Layout>
      <Seo title="Research" />
      {allMarkdownRemark.edges.map((item, i) => {
        let content = item.node.frontmatter

        return (
          <>
            {/* <BannerResearch data={content.banner} /> */}
            <HorizontalCards {...content.horizontalCards1} variant="logos" />
            {/* <VerticalCards {...content.verticalCards1} /> */}
            {/* <HorizontalCards {...content.horizontalCards2} /> */}
            {/* <VerticalDetailsCards {...content.verticalDetailsCards} /> */}
            <Logos {...content.logos} />
            <VerticalCards {...content.verticalCards2} />
          </>
        )
      })}
    </Layout>
  )
}

export default Research
