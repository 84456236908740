import React from "react"
import styled from "styled-components"

import HeaderText from "../../atoms/HeaderText"

const SectionContainer = styled.section`
  padding-block: 52px;
  flex: 1;
  width: 100%;

  &:nth-of-type(even) {
    background: #f4f4f4;
  }

  ${props =>
    props.noBg &&
    `
    padding-top: 24px !important;
    background: transparent !important;

    & + & {
      background: #f4f4f4;
    }
  `}

  @media only screen and (min-width: ${props =>
    props.theme.screenBreakpoints.desktopSmallMin}px) {
    padding-block: 104px;
  }
`

const Container = styled.div`
  padding-inline: 28px;
  margin-inline: auto;
  max-width: 1220px;
  box-sizing: content-box;

  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    padding-inline: 45px;
  }

  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktopSmallMin}px) {
    padding-inline: 120px;
  }
`
const HeaderContent = styled.div`
  margin-bottom: 32px;
  text-align: center;

  p {
    font-size: 22px;
    line-height: 1.25;
    text-transform: uppercase;
    margin: 0;
  }

  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktopSmallMin}px) {
    margin-bottom: 87px;

    p {
      font-size: 32px;
    }
  }
`

const Section = ({ header, noBg, children }) => {
  return (
    <SectionContainer noBg={noBg}>
      <Container>
        <HeaderContent>
          <HeaderText color={"text"}>{header}</HeaderText>
        </HeaderContent>
        {children}
      </Container>
    </SectionContainer>
  )
}

export default Section
