import React, { useState, useEffect } from "react"
import styled from "styled-components"
import P from "../../atoms/P"
import HeaderText from "../../atoms/HeaderText"
import useMediaType from "../../hooks/useMediaType"

const Container = styled.div`
  background: linear-gradient(176deg, #f1f2f2 63%, #15c5cf 100%);
  flex: 1;
  width: 100%;
`

const ContentContainer = styled.div`
  display: grid;
  width: 100vw;
  padding: 50px 28px;
  max-width: 1460px;
  margin: 0 auto;

  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    grid-template-columns: 1fr auto;
    gap: 62px;
    padding-left: 45px;
    padding-right: 45px;
    padding-block: 80px;
    min-height: 300px;
    align-items: center;
  }

  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktopSmallMin}px) {
    padding-left: 120px;
    padding-right: 120px;
  }
`

const ParagraphWrapper = styled.div`
  max-width: 661px;

  p {
    font-size: 14px;
    margin: 0;
  }

  a {
    font-family: "Graphik Web Regular";
    color: inherit;
    font-weight: 700;
    text-decoration: underline;

    &:hover {
      color: #15c5cf;
    }
  }

  //tablet
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    p {
      font-size: 18px;
    }
  }
`

const BannerContact = ({ data }) => {
  const mediaType = useMediaType()

  const headerSizeHandler = media => {
    switch (media) {
      case "desktop":
        return 1
      case "desktopSmall":
        return 2
      case "tablet":
        return 2
      case "mobile":
        return 2
      default:
    }
  }
  const paragraphSizeHandler = media => {
    switch (media) {
      case "desktop":
        return "medium"
      case "desktopSmall":
        return "medium"
      case "tablet":
        return "medium"
      case "mobile":
        return "small"
      default:
    }
  }
  const [PSize, setPSize] = useState()
  const [HSize, setHSize] = useState()

  useEffect(() => {
    setPSize(paragraphSizeHandler(mediaType))
    setHSize(headerSizeHandler(mediaType))
  }, [mediaType])

  return (
    <Container>
      <ContentContainer>
        <HeaderText size={HSize} color="text">
          {data.header}
        </HeaderText>
        <ParagraphWrapper>
          <P
            size={PSize}
            color="text"
            dangerouslySetInnerHTML={{ __html: data.body }}
          />
        </ParagraphWrapper>
      </ContentContainer>
    </Container>
  )
}

export default BannerContact
