import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

import Section from "../../molecules/Section"
import P from "../../atoms/P"

import ArrowButtonBlue from "../../../assets/icons/arrow.svg"

const List = styled.div`
  display: grid;
  gap: 42px;

  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktopSmallMin}px) {
    gap: 32px;
    grid-template-columns: repeat(3, 1fr);
  }

  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktop}px) {
    gap: 57px;
  }
`

const Item = styled.a`
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px;
    align-items: center;
  }

  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktopSmallMin}px) {
    display: block;
  }
`

const ItemImage = styled.div`
  overflow: hidden;
  border-radius: 0px 30px;
  background: var(
    --norbert-health-gradient-03,
    linear-gradient(
      16deg,
      #15c5cf 0%,
      #17c1d0 24%,
      #1bb4d3 48%,
      #23a0d8 73%,
      #2d83df 98%,
      #2e80e0 100%
    )
  );
  aspect-ratio: 362/210;
`

const ItemStyledImage = styled(GatsbyImage)`
  aspect-ratio: 362/210;
  transition: 0.3s transform;

  ${Item}:hover & {
    transform: scale(1.05);
  }
`

const ItemContent = styled.div``

const StyledP = styled(P)`
  margin-block: 16px;

  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    margin-top: 0;
  }

  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktopSmallMin}px) {
    margin-block: 27px 32px;
  }
`

const ItemCta = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: "Graphik Web Regular";
  font-style: normal;
  font-weight: 500;
  color: #2e80e0;
  text-transform: uppercase;
`

const VerticalCards = ({ header, ctaText, items }) => {
  return (
    <Section header={header}>
      <List>
        {items.map((item, i) => (
          <Item
            key={i}
            href={item.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ItemImage>
              {item?.image_path?.childImageSharp && (
                <ItemStyledImage
                  image={item.image_path.childImageSharp.gatsbyImageData}
                  alt=""
                />
              )}
            </ItemImage>
            <ItemContent>
              <StyledP size="medium" color="text">
                {item.body}
              </StyledP>
              <ItemCta>
                {ctaText}
                <ArrowButtonBlue />
              </ItemCta>
            </ItemContent>
          </Item>
        ))}
      </List>
    </Section>
  )
}

export default VerticalCards
