import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

import Section from "../../molecules/Section"
import P from "../../atoms/P"

import ArrowButtonBlue from "../../../assets/icons/arrow.svg"

const List = styled.div`
  display: grid;
  gap: 42px;

  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    gap: 31px;
    grid-template-columns: 1fr 1fr;
  }
`

const Item = styled.div`
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px;
    align-items: center;
  }

  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktopSmallMin}px) {
    grid-template-columns: 440px 1fr;
  }

  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktop}px) {
    gap: 96px;
  }
`

const ItemImage = styled.div`
  padding: 24px;
  border-radius: 0px 30px;
  background: var(
    --norbert-health-gradient-03,
    linear-gradient(
      16deg,
      #15c5cf 0%,
      #17c1d0 24%,
      #1bb4d3 48%,
      #23a0d8 73%,
      #2d83df 98%,
      #2e80e0 100%
    )
  );

  ${props =>
    props.variant === "logos" &&
    `
    --border-size: 12px;

    position: relative;
    aspect-ratio: 440/236;

    &:after {
      content: '';
      position: absolute;
      top: var(--border-size);
      bottom: var(--border-size);
      left: var(--border-size);
      right: var(--border-size);
      z-index: 1;
      background: #fff;
      border-radius: 0px 20px;
    }
  `}
`

const ItemStyledImage = styled(GatsbyImage)`
  overflow: hidden;
  border-radius: 7px;
  border: 4px solid #f2f4f7;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  aspect-ratio: 392/223;

  ${props =>
    props.variant === "logos" &&
    `
    position: absolute !important;
    top: 50%;
    left: 50%;
    width: 100%;
    max-width: 75%;
    z-index: 2;
    transform: translate(-50%, -50%);
    box-shadow: unset;
    border: 0;
    aspect-ratio: unset;
  `}
`

const ItemContent = styled.div`
  max-width: 664px;
`

const StyledP = styled(P)`
  margin-block: 16px 0;

  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    margin-top: 0;
  }
`

const ItemCta = styled.a`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
  font-family: "Graphik Web Regular";
  font-style: normal;
  font-weight: 500;
  color: #2e80e0;
  text-transform: uppercase;

  &:hover {
    text-decoration: none;
  }

  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    margin-top: 32px;
  }
`

const HorizontalCards = ({ header, ctaText, items, variant }) => {
  return (
    <Section header={header}>
      <List>
        {items.map((item, i) => (
          <Item key={i}>
            <ItemImage variant={variant}>
              <ItemStyledImage
                image={item.image_path.childImageSharp.gatsbyImageData}
                alt=""
                variant={variant}
              />
            </ItemImage>
            {/* <ItemContent>
              <StyledP size="medium" color="text">
                {item.body }
              </StyledP>
              
              {item.url && (
                <ItemCta
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {ctaText}
                  <ArrowButtonBlue />
                </ItemCta>
              )}
            </ItemContent> */}
          </Item>
        ))}
      </List>
    </Section>
  )
}

export default HorizontalCards
