import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Section from "../../molecules/Section"

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 32px;

  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    gap: 52px;
  }

  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktopSmallMin}px) {
    gap: 52px 105px;
  }
`

const Item = styled.div`
  @media only screen and (max-width: ${props =>
      props.theme.screenBreakpoints.mobileMax}px) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`

const ItemImage = styled.div`
  [data-gatsby-image-wrapper] {
    max-width: var(--logos-item-image-max-width-mobile);

    @media only screen and (min-width: ${props =>
        props.theme.screenBreakpoints.desktopSmallMin}px) {
      max-width: var(--logos-item-image-max-width-desktop);
    }
  }
`

const ItemStyledImage = styled(GatsbyImage)``

const Logos = ({ header, items }) => {
  return (
    <Section header={header} noBg>
      <List>
        {items.map((item, i) => {
          const image = getImage(item.image_path)

          return (
            <Item key={i}>
              <ItemImage>
                {item?.image_path?.childImageSharp && (
                  <ItemStyledImage
                    image={image}
                    style={{
                      "--logos-item-image-max-width-mobile": `${
                        (image?.width / 2 ?? 1) * 0.65
                      }px`,
                      "--logos-item-image-max-width-desktop": `${
                        image?.width / 2 ?? 1
                      }px`,
                    }}
                    alt=""
                  />
                )}
              </ItemImage>
            </Item>
          )
        })}
      </List>
    </Section>
  )
}

export default Logos
